<template>
  <div class="container-map"></div>
</template>
<script>
export default {
  name: "Map",
}
</script>
<style lang="scss" scoped>
  .container-map {
    width: 100%;
    height: 56.7rem;
    background-image: url('../../../assets/images/constructions/others/image-map.png');
    background-size: cover;
    background-position: center center;
  }
</style>