export default {
  projectThemePark: {
    title: '',
    subtitle: 'Theme Park Project',
    list: [
      {id: 'Universal Studios Singapore', name: 'Universal Studios Singapore', image: require('@/assets/images/constructions/project-theme-park/studio/USS.gif') },
      {id: 'Maritime Experiential Museum', name: 'Maritime Experiential Museum', image: require('@/assets/images/constructions/project-theme-park/museum/DJI_0026.gif') },
    ]
  },
  projectPublic: {
    title: '',
    subtitle: 'Public & Industrial Project',
    list: [
      {id: 'Singapore Expo', name: 'Singapore Expo', image: require('@/assets/images/constructions/project-public/expo/1.jpg')},
      {id: 'Woodlands Checkpoint', name: 'Woodlands Checkpoint', image: require('@/assets/images/constructions/project-public/woodlands/1.jpg')},
      {id: 'Keppel Distripark', name: 'Keppel Distripark', image: require('@/assets/images/constructions/project-public/keppel-distripark/img-kepple.jpg')},
      {id: 'Tagore 66KV Electrical Substation', name: 'Tagore 66KV Electrical Substation', image: require('@/assets/images/constructions/project-public/tagore/111625030014_.pic.jpg')},
    ]
  },
  projectHDB: {
    title: '',
    subtitle: 'Building Project',
    list: [
      {id: 'Sunshine Gardens', name: 'Sunshine Gardens', image: require('@/assets/images/constructions/project-hdb/sunshine-gardens/1.jpg')},
      {id: 'Punggol Emerald', name: 'Punggol Emerald', image: require('@/assets/images/constructions/project-hdb/punggol-emerald/1.gif')},
      {id: 'Anchorvale Horizon', name: 'Anchorvale Horizon', image: require('@/assets/images/constructions/project-hdb/anchorvale-horizon/1.gif')},
      {id: 'The ALPS', name: 'The ALPS', image: require('@/assets/images/property-dev/alps/1.jpg') },
      {id: 'Poiz', name: 'Poiz', image: require('@/assets/images/property-dev/poiz/2.jpg') },
      {id: 'One Canberra', name: 'One Canberra', image: require('@/assets/images/property-dev/one-canberra/4.jpg') },
      {id: 'Canberra Residences', name: 'Canberra Residences', image: require('@/assets/images/property-dev/canberra-residences/3.jpg') },
      {id: 'The Canopy', name: 'The Canopy', image: require('@/assets/images/property-dev/one-canopy/2.jpg') },
      {id: 'The Nautical', name: 'The Nautical', image: require('@/assets/images/property-dev/nautical/1.jpg')},
      {id: 'Forestville', name: 'Forestville', image: require('@/assets/images/property-dev/forestville/1.jpg')},
      {id: 'Sea Horizon', name: 'Sea Horizon', image: require('@/assets/images/property-dev/sea-horizon/1.jpg')},
      {id: 'Queens Peak', name: 'Queens Peak', image: require('@/assets/images/property-dev/queens-peak/1.jpg')},
      {id: 'NorthWave', name: 'NorthWave', image: require('@/assets/images/property-dev/north-wave/1.jpg')},
      {id: 'One Bernam', name: 'One Bernam', image: require('@/assets/images/property-dev/one-bernam/ClubhouseNite-6K-Fn4.gif')},
      {id: 'Provence Residence', name: 'Provence Residence', image: require('@/assets/images/property-dev/provence-residence/PR6.gif')},
      {id: 'Landmark', name: 'Landmark', image: require('@/assets/images/property-dev/landmark/7.gif')},
      {id: 'Sky Villa', name: 'Sky Villa', image: require('@/assets/images/property-dev/sky-vila/1.jpg')},
      {id: 'JKT Living Star', name: 'JKT Living Star', image: require('@/assets/images/property-dev/living-star/6.gif')},
      {id: 'The Santorini', name: 'The Santorini', image: require('@/assets/images/property-dev/the-santorini/1.jpg')},
    ]
  },
  onSalesProjects: [
    {
      title: 'Project Under Construction',
      subtitle: 'Singapore',
      projects: [
        /* {
          id: 'Mandai Rainforest Park',
          name: 'Mandai Rainforest Park', 
          location: 'Singapore', 
          desc: 'The Mandai Rainforest Park is MCC Singapore’s second major integrated theme park project. Located along Mandai Lake Road, facing the new Bird Park, it will also feature walking trails and visitors can expect to set out on a multi-layered adventure in the new 12.5-ha Rainforest Park on the forest floor pathways to the tree-top canopies.',
          images: [
            require('@/assets/images/constructions/mandai/rain-forest.gif'),
            require('@/assets/images/constructions/mandai/DJI_0867.gif'),
          ]
        }, */
        {
          id: 'Changi East Depot',
          name: 'Changi East Depot', 
          location: 'Singapore', 
          desc: 'The Changi East Depot designed and constructed by MCC Singapore, with China Jingye Engineering Corporation Limited (Singapore Branch) as the contractor, will be the second LTA-related project undertaken by the company since the Bedok South Station and Tunnels project for the Thomson-East Coast Line (TEL). ',
          images: [
            require('@/assets/images/constructions/changi-east/10-10.jpg'),
            require('@/assets/images/constructions/changi-east/10-6b.jpg'), 
            require('@/assets/images/constructions/changi-east/10-7.jpg'), 
            require('@/assets/images/constructions/changi-east/10-8.jpg'),
            require('@/assets/images/constructions/changi-east/10-9.jpg'), 
          ]
        },
        {
          id: 'Normanton Park',
          name: 'Normanton Park', 
          location: 'Singapore', 
          desc: 'Normanton Park is served by the Kent Ridge MRT Station and is just a short drive away from the Queensway Shopping Centre, Anchorpoint Shopping Centre, West Coast Plaza and the Harbourfront Centre. Within reach are various entertainment and dining options including Queenstown Stadium, Temasek Club, Singapore Botanic Gardens and Tanglin Halt Food Centre.',
          images: [
            require('@/assets/images/constructions/normanton-park/normanton-park-v4-singapore-scaled.gif'), 
            require('@/assets/images/constructions/normanton-park/Normanton-Park-Drone-View.gif'), 
            require('@/assets/images/constructions/normanton-park/DJI_0564.gif'),
            require('@/assets/images/constructions/normanton-park/DJI_0569.gif')
          ]
        },
        {
          id: 'T311 Bedok South Station',
          name: 'T311 Bedok South Station', 
          location: 'Singapore', 
          desc: 'Designed and constructed by China Jingye Engineering Corporation Limited (Singapore Branch), the Bedok South Station and Tunnels project is an underground station with tunnels spanning 270 metres. Together with Bayshore, Marine Parade, Marine Terrace, Siglap and Tanjong Rhu stations.',
          images: [
            require('@/assets/images/constructions/t311/1.jpg'), 
            require('@/assets/images/constructions/t311/2.jpg'), 
          ]
        },
        {
          id: 'Jalan Papan Factory & Office',
          name: 'Jalan Papan Factory & Office', 
          location: 'Singapore', 
          desc: 'The six-storey office building includes a parking lot, an in-house cafeteria, a multi-purpose hall, a roof garden, a koi pond and other facilities with a view of the Jurong River. The in-house cafeteria on the second floor will serve meals to employees working in the office and provide meal service to workers in various projects of the company.',
          images: [
            require('@/assets/images/constructions/jalan-papan/图片4.jpg'),
            require('@/assets/images/constructions/jalan-papan/图片2.png'), 
            require('@/assets/images/constructions/jalan-papan/图片3.png'), 
          ]
        },
        {
          id: 'Short Street Hotel',
          name: 'Short Street Hotel', 
          location: 'Singapore', 
          desc: 'The Short Street Hotel project with a contract sum of S$60 million covers an area of 2,251 square metres. The building comprises 14 floors, one basement level and a total of 528 guest rooms. Located near an MRT station and only five to six metres away from the heritage conservation area, the project commenced work in 2020 with completion slated for 2022.',
          images: [
            require('@/assets/images/constructions/short-street/ShortSt_trim01.gif'), 
          ]
        },
      ]
    },
  ]
}