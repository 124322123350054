<template>
  <div class="container-construction" v-iScroll>
    <gap :height="86.07" />

    <div class="row-instruction flex-row">
      <Instruction class="left" @show-all="showAllProject"/>
      <gap :width="31.18" />
      <Awards class="flex-1"/>
    </div>

    <gap :height="22" />

    <Map/>

    <div ref="titleOfSectionOne">
      <template v-for="item in langPackage.onSalesProjects">
        <OnSaleProjects :key="item.title" :list="item" />
      </template>
    </div>
    
    <gap :height="54" />

    <!-- Other Project -->
    <div class="container-other-project">
      <Projects :data="langPackage.projectThemePark" />

      <Projects :data="langPackage.projectPublic" />

      <Projects :data="langPackage.projectHDB" />
    </div>

  </div>
</template>
<script>
import jsonEn from "./json/json-en"
import jsonZh from "./json/json-zh"
import Map from "./components/Map.vue"
import Awards from "./components/Awards.vue"
import Instruction from "./components/Instruction.vue"
import OnSaleProjects from "@/views/property-dev/components/OnSaleProjects.vue"
import Projects from "@/views/property-dev/components/Projects.vue"

export default {
  components: {
    Instruction, Awards, Map, OnSaleProjects,Projects
  },
  data() {
    return {
      langPackage: jsonEn,
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    }
  },
  watch: {
    lang(newVal) {
      this.langPackage = newVal === 'en' ? jsonEn : jsonZh;
    }
  },
  created() {
    this.langPackage = this.lang === 'en' ? jsonEn : jsonZh;
  },
  methods: {
    showAllProject() {
      const $ = window.$;
      const el = this.$refs.titleOfSectionOne;
      const h = $(el).offset().top;

      $("body,html").animate(
        { scrollTop: h - 60 },
        200
      );
    }
  }
}
</script>
<style lang="scss" scoped>
  .container-construction {
    padding-bottom: 1.1rem;
    padding: 0 10rem;

    .row-instruction {
      .left { width: 71%; }
    }
  }
</style>