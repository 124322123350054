export default {
  projectThemePark: {
    title: '',
    subtitle: '特色主题公园',
    list: [
      {id: 'Universal Studios Singapore', name: '新加坡环球影城', image: require('@/assets/images/constructions/project-theme-park/studio/USS.gif') },
      {id: 'Maritime Experiential Museum', name: '新加坡海事博物馆', image: require('@/assets/images/constructions/project-theme-park/museum/DJI_0026.gif') },
    ]
  },
  projectPublic: {
    title: '',
    subtitle: '公共基础项目',
    list: [
      {id: 'Singapore Expo', name: '新加坡会展中心', image: require('@/assets/images/constructions/project-public/expo/1.jpg')},
      {id: 'Woodlands Checkpoint', name: '兀兰关口', image: require('@/assets/images/constructions/project-public/woodlands/1.jpg')},
      {id: 'Keppel Distripark', name: '新加坡吉宝物流中心', image: require('@/assets/images/constructions/project-public/keppel-distripark/img-kepple.jpg')},
      {id: 'Tagore 66KV Electrical Substation', name: 'Tagore 66KV变电站', image: require('@/assets/images/constructions/project-public/tagore/111625030014_.pic.jpg')},
    ]
  },
  projectHDB: {
    title: '',
    subtitle: '房建项目',
    list: [
      {id: 'Sunshine Gardens', name: 'Sunshine Gardens', image: require('@/assets/images/constructions/project-hdb/sunshine-gardens/1.jpg')},
      {id: 'Punggol Emerald', name: 'Punggol Emerald', image: require('@/assets/images/constructions/project-hdb/punggol-emerald/1.gif')},
      {id: 'Anchorvale Horizon', name: 'Anchorvale Horizon', image: require('@/assets/images/constructions/project-hdb/anchorvale-horizon/1.gif')},
      {id: 'The ALPS', name: '阿尔卑斯', image: require('@/assets/images/property-dev/alps/1.jpg') },
      {id: 'Poiz', name: '博雅居', image: require('@/assets/images/property-dev/poiz/2.jpg') },
      {id: 'One Canberra', name: 'One Canberra', image: require('@/assets/images/property-dev/one-canberra/4.jpg') },
      {id: 'Canberra Residences', name: 'Canberra Residences', image: require('@/assets/images/property-dev/canberra-residences/3.jpg') },
      {id: 'The Canopy', name: 'The Canopy', image: require('@/assets/images/property-dev/one-canopy/2.jpg') },
      {id: 'The Nautical', name: 'The Nautical', image: require('@/assets/images/property-dev/nautical/1.jpg')},
      {id: 'Forestville', name: 'Forestville', image: require('@/assets/images/property-dev/forestville/1.jpg')},
      {id: 'Sea Horizon', name: '海上地平线', image: require('@/assets/images/property-dev/sea-horizon/1.jpg')},
      {id: 'Queens Peak', name: '女皇碧苑', image: require('@/assets/images/property-dev/queens-peak/1.jpg')},
      {id: 'NorthWave', name: 'NorthWave', image: require('@/assets/images/property-dev/north-wave/1.jpg')},
      {id: 'One Bernam', name: 'One Bernam', image: require('@/assets/images/property-dev/one-bernam/ClubhouseNite-6K-Fn4.gif')},
      {id: 'Provence Residence', name: 'Provence Residence', image: require('@/assets/images/property-dev/provence-residence/PR6.gif')},
      {id: 'Landmark', name: 'Landmark', image: require('@/assets/images/property-dev/landmark/7.gif')},
      {id: 'Sky Villa', name: 'Sky Villa', image: require('@/assets/images/property-dev/sky-vila/1.jpg')},
      { id: 'JKT Living Star', name: 'JKT Living Star', image: require('@/assets/images/property-dev/living-star/6.gif') },
      { id: 'The Santorini', name: 'The Santorini', image: require('@/assets/images/property-dev/the-santorini/1.jpg') },
      
    ]
  },
  onSalesProjects: [
    {
      title: '在建项目',
      subtitle: '新加坡',
      projects: [
        /* {
          id: 'Mandai Rainforest Park',
          name: '万礼热带雨林公园', 
          location: '新加坡', 
          desc: `万礼生态雨林公园是中冶新加坡继新加坡环球影城项目之后，参与打造的新加坡另一张“旅游名片”。该园是新加坡万礼生态区的重要组成部分，也是世界首个热带雨林主题公园。该园区完工后将守护东南亚濒危珍惜野生动植物，成为亚洲自然生态教育和研究的重要枢纽，而全面完工的万礼生态区作为新加坡旅游业未来重点发展计划的国家级项目，将为新加坡的经济发展增添活力。
          万礼热带雨林公园意在打造全新的主题公园形态，作为近年广受业界关注的生态旅游项目，设计施工难点主要体现在对该区域现有生态环境的保护及可持续发展，在所有设计和施工环节中，将园区内的动植物保护放在首位，同时保证该园作为主题公园的功能性、趣味性，满足游客体验要求。`,
          images: [
            require('@/assets/images/constructions/mandai/rain-forest.gif'),
            require('@/assets/images/constructions/mandai/DJI_0867.gif'),
          ]
        }, */
        {
          id: 'Changi East Depot',
          name: '地铁跨岛线樟宜东', 
          location: '新加坡', 
          desc: `樟宜东车辆段临近樟宜会展中心及樟宜机场，占地面积57万平方米，包括列车停车场、列车维修中心、指挥中心、后勤服务管理办公楼等配套设施。该项目预计于2030年竣工`,
          images: [
            require('@/assets/images/constructions/changi-east/10-10.jpg'),
            require('@/assets/images/constructions/changi-east/10-6b.jpg'), 
            require('@/assets/images/constructions/changi-east/10-7.jpg'), 
            require('@/assets/images/constructions/changi-east/10-8.jpg'),
            require('@/assets/images/constructions/changi-east/10-9.jpg'), 
          ]
        },
        {
          id: 'Normanton Park',
          name: '鑫悦府', 
          location: '新加坡', 
          desc: `鑫悦府项目合同于2018年10月04日签订，开工日期为2018年10月04日，完工日期为2022年02月04日。该项目占地面积63685平方米，总建筑面积为146900平方米，由9栋24层公寓楼和22套联排别墅组成，其中包含两层地下车库和一楼泳池景观层。`,
          images: [
            require('@/assets/images/constructions/normanton-park/normanton-park-v4-singapore-scaled.gif'), 
            require('@/assets/images/constructions/normanton-park/Normanton-Park-Drone-View.gif'), 
            require('@/assets/images/constructions/normanton-park/DJI_0564.gif'),
            require('@/assets/images/constructions/normanton-park/DJI_0569.gif')
          ]
        },
        {
          id: 'T311 Bedok South Station',
          name: '地铁汤申-东海岸线勿洛南站及隧道', 
          location: '新加坡', 
          desc: `
          A project worth S$188 million awarded by the Land Transport Authority (LTA) of Singapore, the Bedok South Station and Tunnels have a land area of 24,274.01 square metres, a total construction area of 19,907.84 square metres and a plot ratio of 0.82. Designed and constructed by China Jingye Engineering Corporation Limited (Singapore Branch), the Bedok South Station and Tunnels project is an underground station with tunnels spanning 270 metres. Together with Bayshore, Marine Parade, Marine Terrace, Siglap and Tanjong Rhu stations, Bedok South station will serve as the sixth Civil Defence shelter along the East Coast stretch of Thomson-East Coast Line (TEL). Works for the Bedok South Station and Tunnels commenced in 2016 with completion slated for 2025.
          The 13-kilometre East Coast stretch of the Thomson-East Coast Line (TEL) will connect commuters living in the eastern parts of Singapore and who are not directly served by the rail network currently, such as those in Tanjong Rhu, Siglap, Marine Parade, Upper East Coast and Bedok South, to the heart of the city. As part of the 43-kilometre long TEL, the East Coast stretch will also connect commuters to the Thomson stretch of the TEL, which serves the North-South Corridor. The East Coast stretch will have nine stations, including a station that interchanges with the Downtown Line at Sungei Bedok. The East Coast stretch of the TEL will be completed in two stages. The first seven stations from Tanjong Rhu to Bayshore will be ready in 2023, while the remaining two stations (Bedok South and Sungei Bedok) will be completed in 2025.
          `,
          images: [
            require('@/assets/images/constructions/t311/1.jpg'), 
            require('@/assets/images/constructions/t311/2.jpg'), 
          ]
        },
        {
          id: 'Jalan Papan Factory & Office',
          name: '中冶新加坡办公综合基地及钢结构工厂', 
          location: '新加坡', 
          desc: `该工程占地面积6600平方米，预计于2023年完工，由办公楼及钢结构工厂两座相连的主体建筑组成。办公楼共6层，内附停车楼、内部食堂、多功能厅、顶楼花园、锦鲤池等设施，东边的裕廊河河景尽收眼底。位于二楼的内部食堂，将为在此办公的员工供应餐点，为公司各个项目的工人提供配餐服务。五楼的多功能厅，会定期举办各类员工活动。`,
          images: [
            require('@/assets/images/constructions/jalan-papan/图片4.jpg'),
            require('@/assets/images/constructions/jalan-papan/图片2.png'), 
            require('@/assets/images/constructions/jalan-papan/图片3.png'), 
          ]
        },
        {
          id: 'Short Street Hotel',
          name: 'Short Street Hotel', 
          location: '新加坡', 
          desc: `Short Street从肃街酒店项目占地面积2251平方米，建筑面积11160平方米，地下一层，地上十四层，共计有528间客房。该项目场地狭小，周边环境复杂，紧邻地铁线路，距离文物保护区只有5至6米，且需先拆后建，整个工期非常紧，距离最近的周边建筑物只有1米的距离，对施工场地布置和整个施工流程的管理要求很高。`,
          images: [
            require('@/assets/images/constructions/short-street/ShortSt_trim01.gif'), 
          ]
        },
      ]
    },
  ]
}